import { SxProps } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridRowModel,
  GridValueGetterParams,
  GridValueSetterParams,
  GridColDef,
  GridRowsProp,
  GridColumnGroupingModel,
  GridPinnedRowsProp,
  GridRenderCellParams,
  GridPaginationModel,
  useGridApiContext,
} from '@mui/x-data-grid-pro';

interface IDataGrid extends DataGridProProps {
  disableColumnTopBorder?: boolean;
  disableCellBorder?: boolean;
}

export const dataGridCellBorderLeftThickClassName = 'MuiDataGrid-cell--thick-border-left';
export const dataGridCellBorderRightThickClassName = 'MuiDataGrid-cell--thick-border-right';

const styles: SxProps = {
  border: 0,
  borderRadius: 0,

  '.MuiDataGrid-columnHeaders': {
    borderBottom: '2px solid #000',
  },

  '.MuiDataGrid-columnHeader--filledGroup': {
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },

    '& .MuiDataGrid-columnHeaderTitleContainer': {
      borderBottomWidth: 0,
    },
  },

  '.MuiDataGrid-columnHeaderRow': {
    '&:only-child': {
      borderTop: '2px solid #000',
      borderBottom: '2px solid #000',
    },
  },

  '.MuiDataGrid-pinnedRows--bottom': {
    '& .MuiDataGrid-row': {
      borderTop: '2px solid #000',
    },

    '& .MuiDataGrid-cell': {
      borderBottomWidth: 0,
    },

    '& .MuiDataGrid-row:last-child .MuiDataGrid-cell': {
      borderRightWidth: 0,
      borderLeftWidth: 0,
    },
  },

  '.success': {
    color: '#007a00',
  },

  '.error': {
    color: '#c92726',
  },

  '.bgSuccess': {
    backgroundColor: '#007a00',
  },

  '.bgError': {
    backgroundColor: '#c92726',
  },

  '.bgInfo': {
    backgroundColor: '#3A66D5',
  },

  '.bgGray': {
    backgroundColor: '#dedede',
  },

  // modifiers
  '.MuiDataGrid-main': {
    [`.${dataGridCellBorderLeftThickClassName}`]: {
      borderLeft: '2px solid #000',
    },

    [`.${dataGridCellBorderRightThickClassName}`]: {
      borderRight: '2px solid #000',
    },
  },
};

const cellBorderStyles = {
  '.MuiDataGrid-virtualScrollerContent': {
    borderRight: '1px solid rgba(0, 0, 0, .12)',
  },
  '.MuiDataGrid-virtualScrollerRenderZone': {
    '& .MuiDataGrid-cell': {
      borderLeft: '1px solid rgba(0, 0, 0, .12)',

      // the "spacer" div inserted by mui does not need a border
      '&:last-child:empty:not(tabindex)': {
        borderLeftWidth: '0',
      },
    },
  },
};

const columnTopBorderStyles = {
  '.MuiDataGrid-columnHeaders': {
    borderRadius: 0,
    borderBottom: '2px solid #000',

    '&:not(:has(.MuiDataGrid-columnHeader--filledGroup))': {
      borderTop: '2px solid #000',
    },
  },
  '.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--filledGroup)': {
    borderTop: '2px solid #000',
  },
};

export const MuiDataGrid = ({
  disableColumnTopBorder = false,
  disableCellBorder = false,
  sx,
  ...rest
}: IDataGrid) => (
  <DataGridPro
    sx={{
      ...styles,
      ...sx,
      ...(!disableColumnTopBorder && columnTopBorderStyles),
      ...(!disableCellBorder && cellBorderStyles),
    }}
    {...rest}
  />
);

export type {
  GridRowModel,
  GridValueGetterParams,
  GridValueSetterParams,
  GridColDef,
  GridRowsProp,
  GridColumnGroupingModel,
  GridPinnedRowsProp,
  GridRenderCellParams,
  GridPaginationModel,
};
export { useGridApiContext };
